<script setup>
    const props = defineProps(['id', 'event']);

    const settings = useState('settings');


    onMounted(() => {
        watch(settings, () => {
            if (settings.value?.disableAdvertising) return;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: props.event ?? 'ad-container-ready',
                id: props.id
            });
        }, { immediate: true });
    });
</script>

<template>
    <slot></slot>
    <div class="w-full" :id="id"></div>
</template>